import React, {FormEvent, useContext, useState} from "react";
import {Btn} from "../common/Btn";

import './Header.css'
import {SearchContext} from "../contexts/search.context";
import {Link} from "react-router-dom";

export const Header = () => {

    const {search, setSearch} = useContext(SearchContext);
    const [inputVal, setInputVal] = useState(search);

    const setSearchfromLocalState = (e: FormEvent) => {
        e.preventDefault();
        setSearch(inputVal);
    };

    return (
        <header>
            <h1>
                <Link to="/">
                    <strong>Mega</strong> Ogłoszenia
                </Link>
            </h1>
            <Btn to="/add" text='Dodaj ogłoszenie'/>
            <form className="search" onSubmit={setSearchfromLocalState}>
                <input type="text" value={inputVal} onChange={e => setInputVal(e.target.value)}/><Btn text='Szukaj'/>
            </form>
        </header>
    )
};